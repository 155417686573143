import { Text } from "react-native";

import styled from "styled-components/native";

import Colors from "./colors";

export const BaseText = styled(Text)`
  font-family: FuturaBTW01-Book;
  font-size: 1rem;
  color: ${Colors.WHITE};
`;

export const BodyText = styled(BaseText)`
  font-family: Futura Medium;
  line-height: 15;
  text-shadow: rgba(0, 0, 0, 0.6) 1px 1px;
`;

export const Header1Text = styled(Text)`
  color: #fff;
  font-family: Bangers Regular;
  font-size: 2.33rem;
  line-height: 34;
  text-align: center;
  text-transform: uppercase;
  text-shadow: rgba(0, 0, 0, 0.6) 1px 1px 2px;
`;

export const Header2Text = styled(Header1Text)`
  color: ${Colors.PURPLE};
  font-family: Bangers Regular;
  font-size: 1.75rem;
  line-height: 22;
  text-align: center;
  text-transform: uppercase;
  text-shadow: rgba(0, 0, 0, 0.6) 1px 1px 2px;
`;

export const ButtonText = styled(Header1Text)`
  color: #000;
  text-shadow: none;
`;

export const TreasureHeaderText = styled(Text)`
  color: white;
  font-family: Bangers Regular;
  font-size: 2.16rem;
  line-height: 34;
  text-transform: uppercase;
  text-shadow: rgba(0, 0, 0, 0.6) 1px 1px;
`;

export const HelpStreamerText = styled(BaseText)`
  color: #fff;
  font-family: Futura ExtraBlack;
  line-height: 15;
  text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 2px;
`;

export const FooterText = styled(BodyText)`
  color: rgba(0, 0, 0, 0.7);
  font-family: Futura ExtraBlack;
  line-height: 15;
  text-shadow: none;
`;

export const LeaderboardCounterText = styled(BodyText)`
  color: #fff;
  line-height: 13;
  text-shadow: none;
`;

export const LeaderboardViewName = styled(BodyText)`
  color: #fff;
  font-family: Futura ExtraBlack;
  line-height: 14;
  text-shadow: none;
`;

export const NavText = styled(BodyText)`
  color: #fff;
  font-family: Futura ExtraBlack;
  text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 1px;
  font-size: 1.5rem;
  opacity: 0.6;
`;
