import React from "react";
import { Image, View } from "react-native";
import styled from "styled-components/native";

const PIRATE_RENDER_LOG_BASE = 2;
const NUM_PIRATE_IMAGES = 9;

const pirateImageMap = {
  a: require("../images/pirate-a.png"),
  b: require("../images/pirate-b.png"),
  c: require("../images/pirate-c.png"),
  d: require("../images/pirate-d.png"),
  e: require("../images/pirate-e.png"),
  f: require("../images/pirate-f.png"),
  g: require("../images/pirate-g.png"),
  h: require("../images/pirate-h.png"),
  i: require("../images/pirate-i.png")
};

export default React.memo(function PirateList({ shipPirateCount }) {
  if (shipPirateCount < 1) {
    return null;
  }
  const getBaseLog = (x, y) => Math.log(y) / Math.log(x);

  const numPiratesToRender = Math.ceil(
    getBaseLog(PIRATE_RENDER_LOG_BASE, shipPirateCount)
  );

  const pirateKey = i => String.fromCharCode((i % numPiratesToRender) + 97);

  return (
    <PirateListContainer>
      {[...new Array(numPiratesToRender)].map((_, i) => (
        <PirateImage
          key={i}
          source={pirateImageMap[pirateKey(i % NUM_PIRATE_IMAGES)]}
          resizeMode="contain"
        />
      ))}
    </PirateListContainer>
  );
});

const PirateImage = styled(Image)`
  width: 20px;
  height: 48px;
`;

const PirateListContainer = styled(View)`
  height: 100%;
  flex: 1;
  flex-direction: row;
`;
