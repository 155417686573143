import React, { useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";

import Colors from "../ui/colors";

import { styles } from "./ui";

import ShipProgress from "./ship-progress";
import PirateList from "./pirate-list";

const getCurrentShipPhase = ({ shipWoodCount, shipLimits }) => {
  let nextShipPhase = null;

  let nextShipLimitIndex = shipLimits.findIndex(({ woodNeeded }) => {
    return shipWoodCount < woodNeeded;
  });

  const currentShipLimitIndex =
    nextShipLimitIndex > -1 ? nextShipLimitIndex - 1 : shipLimits.length - 1;

  let { phase: currentShipPhase, woodNeeded, pirateCapacity } = shipLimits[
    currentShipLimitIndex
  ];

  return {
    currentShipPhase
  };
};

const PIRATE_RENDER_LOG_BASE = 2;

const getBaseLog = (x, y) => Math.log(y) / Math.log(x);

const numPiratesForIsland = shipPirateCount =>
  Math.ceil(getBaseLog(PIRATE_RENDER_LOG_BASE, shipPirateCount));

const Rankings = function({ activeShips, shipLimits }) {
  const [showReachRankings, setShowReachRankings] = useState(true);
  if (activeShips.length < 1 || shipLimits.length < 1) {
    return null;
  }

  const totalPirateAmount = activeShips
    .filter(({ rumAmount }) => rumAmount > 0)
    .reduce((curTotal, { rumAmount }) => {
      return curTotal + numPiratesForIsland(rumAmount);
    }, 0);

  return (
    <View>
      <View style={{ flexDirection: "row", paddingTop: 38 }}>
        <TouchableOpacity onPress={() => setShowReachRankings(true)}>
          <Text
            style={[
              {
                fontFamily: "Futura ExtraBlack",
                fontSize: 12,
                borderColor: Colors.PURPLE,
                borderBottomWidth: showReachRankings ? 4 : 0,
                width: 215,
                textTransform: "uppercase",
                textAlign: "center",
                color: showReachRankings ? "white" : "rgba(255, 255, 255, 0.5)"
              }
            ]}
          >
            Odds of Reaching the Island
          </Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setShowReachRankings(false)}>
          <Text
            style={{
              fontFamily: "Futura ExtraBlack",
              fontSize: 12,
              borderColor: Colors.PURPLE,
              borderBottomWidth: !showReachRankings ? 4 : 0,
              marginLeft: 38,
              width: 156,
              textTransform: "uppercase",
              textAlign: "center",
              color: !showReachRankings ? "white" : "rgba(255, 255, 255, 0.5)"
            }}
          >
            Odds of Grand Prize
          </Text>
        </TouchableOpacity>
      </View>
      {showReachRankings ? (
        <>
          <Text style={styles.bodyText}>
            Here you'll find each ship's current chances of successfully
            reaching Treasure Island. Collect more wood to increase your ship's
            chances!
          </Text>
          <View style={{ width: "100%", paddingTop: 20 }}>
            {activeShips
              .sort(
                (
                  { reachDestinationProbability: reachDestinationProbabilityA },
                  { reachDestinationProbability: reachDestinationProbabilityB }
                ) => reachDestinationProbabilityB - reachDestinationProbabilityA
              )
              .map(
                (
                  {
                    name,
                    reachDestinationProbability,
                    woodAmount: shipWoodCount
                  },
                  i
                ) => {
                  const { currentShipPhase } = getCurrentShipPhase({
                    shipWoodCount,
                    shipLimits
                  });

                  return (
                    <View
                      key={`${name}-${i}`}
                      style={{
                        flexDirection: "row",
                        flex: 1,
                        minHeight: 45,
                        marginVertical: 5
                      }}
                    >
                      <ShipProgress
                        reachDestinationProbability={
                          reachDestinationProbability
                        }
                        currentShipPhase={currentShipPhase}
                      />
                      <View style={[{ padding: 10 }]}>
                        <Text
                          style={{
                            color: "white",
                            fontFamily: "Bangers Regular",
                            textShadowColor: "black",
                            textShadowOffset: { width: 2, height: 0 },
                            textShadowRadius: 10
                          }}
                        >
                          {`${name}`}
                        </Text>
                        <Text
                          style={{
                            color: "white",
                            fontFamily: "Futura Medium",
                            textShadowColor: "black",
                            textShadowOffset: { width: 2, height: 0 },
                            textShadowRadius: 10
                          }}
                        >
                          {`${currentShipPhase.replace(/_/g, " ")}`}
                        </Text>
                      </View>
                    </View>
                  );
                }
              )}
          </View>
        </>
      ) : (
        <>
          <Text style={styles.bodyText}>
            Here you'll find each ship's current chances of winning the weekly
            prize upon successfully reaching Treasure Island (based on your
            gathered pirates relative to other channels' gathered pirates).
            Collect more pirates to increase your ship's chances!
          </Text>
          <View style={{ width: "100%", paddingTop: 20 }}>
            {activeShips
              .sort(
                ({ rumAmount: rumAmountA }, { rumAmount: rumAmountB }) =>
                  rumAmountB - rumAmountA
              )
              .filter(({ rumAmount }) => rumAmount > 0)
              .map(({ name, rumAmount: shipPirateCount }, i) => {
                return (
                  <View
                    key={`${name}-${i}`}
                    style={{
                      flexDirection: "row",
                      flex: 1,
                      minHeight: 45,
                      marginVertical: 5
                    }}
                  >
                    <View style={[{ padding: 10, width: 120 }]}>
                      <Text
                        style={{
                          color: "white",
                          fontFamily: "Bangers Regular",
                          textShadowColor: "black",
                          textShadowOffset: { width: 2, height: 0 },
                          textShadowRadius: 10
                        }}
                      >
                        {`${name}`}
                      </Text>
                      <Text
                        style={{
                          color: "white",
                          fontFamily: "Futura Medium",
                          textShadowColor: "black",
                          textShadowOffset: { width: 2, height: 0 },
                          textShadowRadius: 10
                        }}
                      >
                        {`${(
                          (numPiratesForIsland(shipPirateCount) /
                            totalPirateAmount) *
                          100
                        ).toFixed(0)}%`}
                      </Text>
                    </View>
                    <PirateList shipPirateCount={shipPirateCount} />
                  </View>
                );
              })}
          </View>
        </>
      )}
    </View>
  );
};

Rankings.path = "rankings";

Rankings.navigationOptions = {
  title: "Treasure of the Seas - Rankings",
  linkName: "Rankings"
};

export default Rankings;
