import React, { useEffect, useRef } from "react";
import { Animated, Image, StyleSheet, View } from "react-native";
import Colors from "../ui/colors";

const resourceImageMap = {
  RAFT: {
    FLAG: require("../images/raft-flag.png"),
    BODY: require("../images/raft-body.png")
  },
  GALLY: {
    FLAG: require("../images/gally-flag.png"),
    BODY: require("../images/gally-body.png")
  },
  LUGGER: {
    FLAG: require("../images/lugger-flag.png"),
    BODY: require("../images/lugger-body.png")
  },
  MAN_OF_WAR: {
    FLAG: require("../images/man-of-war-flag.png"),
    BODY: require("../images/man-of-war-body.png")
  },
  SHIP_OF_THE_LINE: {
    FLAG: require("../images/ship_of_the_line_flag.png"),
    BODY: require("../images/ship_of_the_line_body.png")
  }
};

export default function ShipProgress({
  reachDestinationProbability = 0,
  currentShipPhase
}) {
  const animatedShipProgress = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(animatedShipProgress, {
      toValue: reachDestinationProbability,
      duration: 2000,
      extrapolate: "clamp"
    }).start();
  }, [reachDestinationProbability]);

  return (
    <View
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        flexDirection: "row"
      }}
    >
      <Animated.View
        style={{
          borderRadius: 5,
          width: animatedShipProgress.interpolate({
            inputRange: [0, 100],
            outputRange: ["0%", "100%"]
          }),
          backgroundColor: Colors.BLUE,
          flexDirection: "row",
          justifyContent: "flex-end"
        }}
      />
      <View
        style={{
          height: "100%",
          minWidth: 40,
          justifyContent: "center",
          marginLeft: -40,
          paddingBottom: 10
        }}
      >
        <Image
          style={{
            position: "absolute",
            width: "160%",
            height: "160%"
          }}
          source={
            resourceImageMap[currentShipPhase] &&
            resourceImageMap[currentShipPhase].FLAG
          }
          resizeMode="contain"
        />
        <Image
          style={{
            position: "absolute",
            width: "160%",
            height: "160%"
          }}
          source={
            resourceImageMap[currentShipPhase] &&
            resourceImageMap[currentShipPhase].BODY
          }
          resizeMode="contain"
        />
      </View>
    </View>
  );
}
