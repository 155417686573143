import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { API, graphqlOperation } from "aws-amplify";
import useInterval from "use-interval";

import {
  Animated,
  Dimensions,
  Easing,
  Image,
  ScrollView,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import styled from "styled-components/native";

import * as mutations from "../mutations";
import * as queries from "../queries";
import Colors from "../ui/colors";
import { ButtonText, BodyText } from "../ui/text";
import { hashJSON } from "./util";

const BIRD_PATH_Y = 10;
const BIRD_SIZE = 50;
const BIRD_TIMER = 3000;
const GAME_SCENE_HEIGHT = 500;

const IMAGE_FUDGE = Math.PI / 12;

const MAKE_THINGS_MOVE_STREAK_COUNT = 5;

const CannonGame = function() {
  const animatedAngle = useRef(new Animated.Value(1)).current;
  const animatedBirdFlight = useRef(new Animated.Value(0)).current;
  const animatedBirdFlightInterpolatedX = useRef(null);
  const animatedAngleTiming = useRef(null);
  const animatedHitBird = useRef(new Animated.Value(0));
  const [isBirdFlyingRight, setIsBirdFlyingRight] = useState(true);
  const [isCannonBallInAir, setIsCannonBallInAir] = useState(false);
  const [hasHitBird, setHasHitBird] = useState(false);
  const initBallPosition = useRef(null);
  const cannonBallPosition = useRef(new Animated.ValueXY({ x: 0, y: 0 }));
  const [stopAngle, setStopAngle] = useState(null);
  const [messageText, setMessageText] = useState("Catch some parrots");
  const [parrotStreakCounter, setParrotStreakCounter] = useState(0);
  const cannonBallAnimationListener = useRef(null);
  const [accessToken, setAccessToken] = useState(null);
  const [twitchUser, setTwitchUser] = useState(null);
  const [streamerId, setStreamerId] = useState(null);
  const [currentPlunderPhase, setCurrentPlunderPhase] = useState("PLUNDERING");
  const [warningCounter, setWarningCounter] = useState(0);
  const [plunderWindows, setPlunderWindows] = useState({});
  const phaseWarningTimer = useRef(null);

  const [
    listCannonGameStreakEntries,
    setListCannonGameStreakEntries
  ] = useState([]);

  const moveCannonAngleLeft = useCallback(() => {
    if (parrotStreakCounter >= MAKE_THINGS_MOVE_STREAK_COUNT) {
      return;
    }

    animatedAngleTiming.current = Animated.timing(animatedAngle, {
      duration: 2000,
      toValue: 0
    });

    animatedAngleTiming.current.start();
  }, [animatedAngle, parrotStreakCounter]);

  const moveCannonAngleRight = useCallback(() => {
    if (parrotStreakCounter >= MAKE_THINGS_MOVE_STREAK_COUNT) {
      return;
    }

    animatedAngleTiming.current = Animated.timing(animatedAngle, {
      duration: 2000,
      toValue: 1
    });

    animatedAngleTiming.current.start();
  }, [animatedAngle, parrotStreakCounter]);

  useEffect(() => {
    Animated.timing(animatedBirdFlight, {
      duration: BIRD_TIMER,
      toValue: isBirdFlyingRight ? 1 : 0
    }).start(() => {
      setIsBirdFlyingRight(!isBirdFlyingRight);
    });

    animatedBirdFlightInterpolatedX.current = animatedBirdFlight.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1010],
      extrapolate: "clamp"
    });
  }, [isBirdFlyingRight]);

  useEffect(() => {
    const streaksQuery = async () => {
      const {
        data: {
          listCannonGameStreakEntries: { items: gameStreakEntries },
          getPlunderWindows
        }
      } = await API.graphql(
        graphqlOperation(queries.listCannonGameStreakEntries)
      );

      setListCannonGameStreakEntries(gameStreakEntries);
      setPlunderWindows(getPlunderWindows);
      return gameStreakEntries;
    };

    const fetchUserOnOAuth = async ({ accessToken, gameStreakEntries }) => {
      const { data } = await axios({
        method: "POST",
        url: process.env.TWITCH_OAUTH_ENDPOINT,
        data: JSON.stringify({
          accessToken
        })
      });

      let streamerId = localStorage.getItem("streamerId") || null;

      let {
        data: [{ id: twitchUserId, login, display_name: displayName, email }]
      } = JSON.parse(data);

      setTwitchUser({ twitchUserId, login, display_name: displayName, email });
      setMessageText(`Welcome, ${displayName}. Let's play.`);
      analytics.identify(twitchUserId, {
        userId: twitchUserId,
        traits: {
          streamerId: streamerId
        }
      });
      analytics.track("COPY_CANNON_GAME_LINK", {
        userId: twitchUserId,
        properties: {
          displayName,
          email,
          streamerId
        }
      });

      const entryExists = gameStreakEntries.find(({ id }) => {
        return id === Number(twitchUserId);
      });

      if (entryExists) {
        return;
      }

      await API.graphql(
        graphqlOperation(mutations.createCannonPlayer, {
          input: {
            id: Number(twitchUserId),
            email,
            displayName,
            topScore: 0,
            streamerId: Number(streamerId)
          }
        })
      );
    };

    (async () => {
      try {
        const gameStreakEntries = await streaksQuery();

        const params = new URLSearchParams(window.location.search);
        const streamerIdFromQuery = params.get("streamerId");

        if (streamerIdFromQuery && localStorage) {
          localStorage.setItem("streamerId", Number(streamerIdFromQuery));
        }

        const twitchCredentials = hashJSON();
        if (!twitchCredentials) {
          return;
        }

        await fetchUserOnOAuth({
          accessToken: twitchCredentials.access_token,
          gameStreakEntries
        });

        setAccessToken(twitchCredentials.access_token);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  useInterval(() => {
    const streaksQuery = async () => {
      const {
        data: {
          listCannonGameStreakEntries: { items: gameStreakEntries }
        }
      } = await API.graphql(
        graphqlOperation(queries.listCannonGameStreakEntries)
      );

      setListCannonGameStreakEntries(gameStreakEntries);

      return gameStreakEntries;
    };
    streaksQuery();
  }, 5000);

  useEffect(() => {
    cannonBallAnimationListener.current = cannonBallPosition.current.addListener(
      ({ x: cannonX, y }) => {
        if (initBallPosition.current.y + y < 0) {
          setHasHitBird(false);
          setParrotStreakCounter(0);
          setMessageText("Yikes.");
          return;
        }
        if (
          initBallPosition.current.y + y <= BIRD_SIZE / 2 &&
          initBallPosition.current.y + y >= 0
        ) {
          const interpolatedBirdX = animatedBirdFlight.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 1010],
            extrapolate: "clamp"
          });

          if (
            Math.abs(
              initBallPosition.current.x +
                cannonX -
                BIRD_SIZE / 2 -
                interpolatedBirdX.__getValue() +
                15
            ) <= 50 &&
            !hasHitBird
          ) {
            setHasHitBird(true);
            setMessageText(`Nice!`);
            setParrotStreakCounter(parrotStreakCounter + 1);
            cannonBallPosition.current.setValue({ x: 0, y: 0 });
          }
        }
      }
    );

    return () => {
      cannonBallPosition.current.removeListener(
        cannonBallAnimationListener.current
      );
    };
  }, [parrotStreakCounter, hasHitBird, twitchUser]);

  useEffect(() => {
    const incrementStreak = async () => {
      try {
        const {
          data: { incrementCannonGameStreakEntry }
        } = await API.graphql(
          graphqlOperation(mutations.incrementCannonGameStreakEntry, {
            id: Number(twitchUser.twitchUserId),
            currentScore: parrotStreakCounter
          })
        );
        if (incrementCannonGameStreakEntry) {
          const { topScore } = incrementCannonGameStreakEntry;
          const currentTopStreak = Math.max(
            ...listCannonGameStreakEntries.map(({ topScore }) => topScore)
          );
        }
      } catch (err) {
        console.error(err);
      }
    };
    if (twitchUser && parrotStreakCounter > 0) {
      incrementStreak();
    }

    if (
      parrotStreakCounter >= MAKE_THINGS_MOVE_STREAK_COUNT &&
      !isCannonBallInAir
    ) {
      Animated.loop(
        Animated.sequence([
          Animated.timing(animatedAngle, {
            duration: 4000,
            toValue: 0
          }),
          Animated.timing(animatedAngle, {
            duration: 4000,
            toValue: 1
          })
        ])
      ).start();
    }
  }, [parrotStreakCounter, isCannonBallInAir]);

  useEffect(() => {
    if (isCannonBallInAir) {
      return;
    }
    Animated.sequence([
      Animated.timing(animatedHitBird.current, {
        duration: 1500,
        toValue: 1
      }),
      Animated.timing(animatedHitBird.current, {
        duration: 1500,
        toValue: 0
      })
    ]).start();
  }, [hasHitBird, isCannonBallInAir, isCannonBallInAir]);

  const stopCannonAngle = useCallback(() => {
    if (parrotStreakCounter >= MAKE_THINGS_MOVE_STREAK_COUNT) {
      return;
    }
    animatedAngle.stopAnimation(stopped => {
      const interpolatedAngleStr = animatedAngle.interpolate({
        inputRange: [0, 1],
        outputRange: [`-${Math.PI - IMAGE_FUDGE}rad`, `${IMAGE_FUDGE}rad`],
        extrapolate: "clamp"
      });

      const interpolatedAngle =
        Number(interpolatedAngleStr.__getValue().replace("rad", "")) -
        IMAGE_FUDGE;

      setStopAngle(interpolatedAngle);
    });
  }, [parrotStreakCounter, animatedAngle]);

  const onCannonBaseLayout = useCallback(
    ({
      nativeEvent: {
        layout: { x, y, width, height }
      }
    }) => {
      initBallPosition.current = {
        x: x + width / 2 - 20,
        y: y + height / 2 - 15
      };
    },
    []
  );

  const onFireCannon = useCallback(() => {
    animatedAngle.stopAnimation(stopped => {
      const interpolatedAngleStr = animatedAngle.interpolate({
        inputRange: [0, 1],
        outputRange: [`-${Math.PI - IMAGE_FUDGE}rad`, `${IMAGE_FUDGE}rad`],
        extrapolate: "clamp"
      });

      const interpolatedAngle =
        Number(interpolatedAngleStr.__getValue().replace("rad", "")) -
        IMAGE_FUDGE;

      setIsCannonBallInAir(true);
      Animated.timing(cannonBallPosition.current, {
        duration: 1200,
        toValue: {
          x: Math.cos(interpolatedAngle) * 1500,
          y: Math.sin(interpolatedAngle) * 1500
        }
      }).start(() => {
        cannonBallPosition.current.setValue({ x: 0, y: 0 });
        setIsCannonBallInAir(false);
        setHasHitBird(false);
      });
    });
  }, []);

  useEffect(() => {
    const {
      waitWindowDuration = 180,
      plunderWindowDuration = 60
    } = plunderWindows;

    const totalSecondsWithinPlunderCycle =
      waitWindowDuration + plunderWindowDuration;

    const currentSecondsWithinPlunderWindow =
      totalSecondsWithinPlunderCycle -
      (Math.ceil(
        (new Date().getTime() -
          new Date("2019-07-13T00:00:00.000Z").getTime()) /
          1000
      ) %
        totalSecondsWithinPlunderCycle);

    const plunderPhase =
      currentSecondsWithinPlunderWindow >= plunderWindowDuration
        ? "WAITING"
        : "PLUNDERING";

    setCurrentPlunderPhase(plunderPhase);

    phaseWarningTimer.current = setInterval(() => {
      setWarningCounter(
        currentSecondsWithinPlunderWindow - plunderWindowDuration
      );
    }, 1000);

    return () => {
      clearInterval(phaseWarningTimer.current);
    };
  }, [warningCounter]);

  return (
    <GameSceneContainer activeOpacity={0.5}>
      <SkyBackground>
        <MessageText style={{ fontSize: 18 }}>
          {currentPlunderPhase === "WAITING" &&
            plunderWindows.plunderWindowDuration + warningCounter > 0 &&
            `Pop parrots ${plunderWindows.plunderWindowDuration +
              warningCounter}`}
        </MessageText>
      </SkyBackground>
      <Grass />
      <BirdPath>
        <BirdImageContainer
          style={{
            transform: [
              {
                translateX: animatedBirdFlightInterpolatedX.current
              }
            ]
          }}
        >
          <BirdImage
            style={{
              transform: [{ rotateY: isBirdFlyingRight ? "0deg" : "180deg" }]
            }}
            resizeMode="contain"
            source={require("../images/parrot-flight.png")}
          />
        </BirdImageContainer>
      </BirdPath>
      {initBallPosition.current && (
        <CannonBallImage
          style={{
            top: initBallPosition.current.y,
            left: initBallPosition.current.x,
            transform: [
              {
                translateX: cannonBallPosition.current.x
              },
              {
                translateY: cannonBallPosition.current.y
              }
            ]
          }}
          source={require("../images/cannon-ball.png")}
        />
      )}
      <CannonBodyImage
        source={require("../images/cannon-body.png")}
        style={{
          transform: [
            {
              rotateZ: animatedAngle.interpolate({
                inputRange: [0, 1],
                outputRange: [
                  `-${Math.PI - IMAGE_FUDGE}rad`,
                  `${IMAGE_FUDGE}rad`
                ],
                extrapolate: "clamp"
              })
            },
            {
              translateX: animatedAngle.interpolate({
                inputRange: [0, 1],
                outputRange: [34, 0],
                extrapolate: "clamp"
              })
            },
            {
              translateY: animatedAngle.interpolate({
                inputRange: [0, 0.5, 1],
                outputRange: [0, -18, 0],
                extrapolate: "clamp"
              })
            }
          ]
        }}
      />
      <CannonBaseImage
        onLayout={onCannonBaseLayout}
        source={require("../images/cannon-base.png")}
      />
      <MessageContainer style={{ opacity: animatedHitBird.current }}>
        <MessageText>{`${messageText}`}</MessageText>
        {parrotStreakCounter > 0 && (
          <>
            <MessageText>{` ${parrotStreakCounter}`}</MessageText>
            <Image
              style={{ height: 50, width: 25 }}
              source={require("../images/parrot.png")}
            />
          </>
        )}
      </MessageContainer>

      <CannonControlContainer>
        <MoveCannonButton
          onPressIn={moveCannonAngleLeft}
          onPressOut={stopCannonAngle}
          style={{ marginRight: 50 }}
        >
          <MoveCannonButtonImage
            source={require("../images/small-arrow.svg")}
          />
        </MoveCannonButton>

        {!isCannonBallInAir && (
          <ShootButton onPress={onFireCannon}>
            <ShootImage source={require("../images/shoot.svg")} />
          </ShootButton>
        )}

        <MoveCannonButton
          onPressIn={moveCannonAngleRight}
          onPressOut={stopCannonAngle}
          style={{ marginLeft: 50 }}
        >
          <MoveCannonButtonImage
            style={{
              transform: [{ rotateY: "180deg" }]
            }}
            source={require("../images/small-arrow.svg")}
          />
        </MoveCannonButton>
      </CannonControlContainer>
      <StreakCounterContainer>
        <Image
          style={{ height: 20, width: 20 }}
          source={require("../images/parrot.png")}
        />
        <StreakCounterText>{parrotStreakCounter}</StreakCounterText>
      </StreakCounterContainer>
      <StreakListContainer>
        <StreakListHeader>
          <StreakListHeaderText>Weekly Top Scores</StreakListHeaderText>
        </StreakListHeader>
        <ScrollView>
          {listCannonGameStreakEntries.map(({ displayName, topScore }, i) => (
            <StreakListEntry key={i}>
              <StreakListHeaderText>{displayName}</StreakListHeaderText>
              <StreakListHeaderText>{topScore}</StreakListHeaderText>
            </StreakListEntry>
          ))}
        </ScrollView>
      </StreakListContainer>
      {(!accessToken || currentPlunderPhase === "PLUNDERING") && (
        <PreventPlayOverlay>
          {!accessToken && (
            <Button
              onPress={() => {
                window.location.href = `https://id.twitch.tv/oauth2/authorize?response_type=token+id_token&client_id=${
                  process.env.SITE_TWITCH_CLIENT_ID
                }&redirect_uri=${
                  process.env.CANNON_GAME_URL
                }&scope=openid+user:read:email&claims={"id_token":{"email_verified":null}}`;
              }}
            >
              <ButtonText>Sign in with Twitch to Play!</ButtonText>
            </Button>
          )}
          {accessToken && currentPlunderPhase === "PLUNDERING" && (
            <MessageText
              style={{ fontSize: 25 }}
            >{`Parrot popping is paused. Get back to your streamer and work on that ship.`}</MessageText>
          )}
        </PreventPlayOverlay>
      )}
    </GameSceneContainer>
  );
};

CannonGame.path = "cannon-game";

CannonGame.navigationOptions = {
  title: "Treasure of the Seas - Cannon",
  linkName: "Cannon Game"
};

export default CannonGame;

const PreventPlayOverlay = styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  flex: 1;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
`;

const GameSceneContainer = styled(View)`
  width: 100%;
  height: ${GAME_SCENE_HEIGHT}px;
  background-color: ${Colors.GRAY_DARK};
  justify-content: flex-end;
  align-items: center;
`;

const BirdImage = styled(Image)`
  height: 100%;
  width: 100%;
`;

const BirdImageContainer = styled(Animated.View)`
  height: ${BIRD_SIZE};
  width: ${BIRD_SIZE};
`;

const BirdPath = styled(View)`
  position: absolute;
  width: 100%;
  height: 50px;
  flex-direction: row;
  top: ${BIRD_PATH_Y};
`;

const CannonBodyImage = styled(Animated.Image)`
  height: 75px;
  width: 75px;
  position: absolute;
  bottom: 30px;
`;

const CannonBaseImage = styled(Image)`
  height: 75px;
  width: 75px;
  position: absolute;
  bottom: 30px;
`;

const CannonBallImage = styled(Animated.Image)`
  height: 20px;
  width: 20px;
  position: absolute;
  bottom: 60px;
`;

const CannonControlContainer = styled(View)`
  position: absolute;
  width: 300px;
  bottom: 10px;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
`;

const MessageContainer = styled(Animated.View)`
  position: absolute;
  height: 400px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const MessageText = styled(Text)`
  color: white;
  font-size: 40px;
  font-family: Bangers Regular;
  text-transform: uppercase;
  text-shadow: rgba(0, 0, 0, 0.6) 1px 1px 2px;
`;

const MoveCannonButton = styled(TouchableOpacity)`
  padding: 5px;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${Colors.WHITE};
`;

const MoveCannonButtonImage = styled(Image)`
  height: 30px;
  width: 30px;
`;

const SkyBackground = styled(View)`
  background-color: ${Colors.BLUE};
  flex: 1;
  width: 100%;
  justify-content: flex-end;
  padding: 10px;
`;

const Grass = styled(View)`
  background-color: ${Colors.GREEN};
  height: 60px;
  width: 100%;
`;

const ShootButton = styled(TouchableOpacity)`
  height: 20px;
  width: 50px;
  border-radius: 10px;
  padding: 10px;
`;

const ShootImage = styled(Image)`
  height: 30px;
  width: 30px;
  left: -3px;
`;

const Button = styled(TouchableOpacity)`
  background-color: ${Colors.YELLOW};
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 15px;
`;

const StreakCounterContainer = styled(View)`
  position: absolute;
  bottom: 15px;
  left: 15px;
  padding: 5px;
  flex-direction: row;
  align-items: center;
`;

const StreakListContainer = styled(View)`
  position: absolute;
  bottom: 15px;
  right: 15px;
  padding: 5px;
  height: 200px;
  width: 200px;
  background-color: rgba(0, 0, 0, 0.3);
`;

const StreakListHeader = styled(View)`
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const StreakListEntry = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
`;

const StreakListHeaderText = styled(Text)`
  color: white;
  font-size: 18px;
  font-family: Bangers Regular;
  text-transform: uppercase;
  text-shadow: rgba(0, 0, 0, 0.6) 1px 1px 2px;
`;

const StreakCounterText = styled(Text)`
  color: white;
  font-size: 20px;
  font-family: Bangers Regular;
  text-transform: uppercase;
  text-shadow: rgba(0, 0, 0, 0.6) 1px 1px 2px;
`;
