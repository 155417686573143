export default {
  WHITE: "#FFFFFF",
  PURPLE: "#8752FF",
  PURPLE_DARK: "#452D52",
  PURPLE_LIGHT: "#A37BFF",
  PURPLE_LIGHT_ALT: "#D3BFFF",
  BLUE: "#3ECEFF",
  GREEN: "#60E092",
  YELLOW: "#FFE25C",
  RED: "#FF6E8A",
  GRAY_LIGHT: "#DEDEDE",
  GRAY_MIDDLE: "#979797",
  GRAY_DARK: "#4A4A4A",
  BACKGROUND: "rgba(57, 57, 57, 0.87)"
};
