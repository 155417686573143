import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import useInterval from "use-interval";
import { styles } from "./ui";

import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds
} from "date-fns";

export default function CountDown({ launchDate }) {
  const nextPhase = new Date(launchDate);
  const [countdownDisplay, setCountdownDisplay] = useState("");

  useInterval(
    () => {
      const now = new Date();

      if (now > nextPhase) {
        setCountdownDisplay(null);
        return;
      }

      const padZero = number => number.toString().padStart(2, "0");
      const days = padZero(differenceInDays(nextPhase, now));
      const hours = padZero(differenceInHours(nextPhase, now) % 24);
      const minutes = padZero(differenceInMinutes(nextPhase, now) % 60);
      const seconds = padZero(differenceInSeconds(nextPhase, now) % 60);

      setCountdownDisplay(`${days}:${hours}:${minutes}:${seconds}`);
    },
    1000,
    true
  );

  return (
    <View
      style={{
        justifyContent: "center",
        paddingVertical: 6,
        backgroundColor: "rgba(102, 35, 255, 50)",
        paddingLeft: 70,
        height: 28
      }}
    >
      <Text
        style={[
          styles.headlineText,
          {
            fontSize: "1.16rem",
            color: "white",
            fontFamily: "Futura Medium Italic",
            textShadowColor: "rgba(0,0,0,0.5)",
            textShadowOffset: { width: 1, height: 1 }
          }
        ]}
      >
        {countdownDisplay &&
          `All ships launch in: ${countdownDisplay}! Watch twitch.tv/treasureoftheseas to find out who wins!`}
      </Text>
    </View>
  );
}
