import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";

import { SceneView } from "@react-navigation/core";

import { Link } from "@react-navigation/web";
import { useNavigationEvents } from "react-navigation-hooks";
import { API, graphqlOperation } from "aws-amplify";

import Colors from "../ui/colors";
import { NavText, TreasureHeaderText } from "../ui/text";

import * as queries from "../queries";
import Countdown from "./countdown";

export default function Nav({ descriptors, navigation }) {
  const [events, setEvents] = useState([]);
  useNavigationEvents(evt => {
    setEvents([...events, evt.type]);
  });
  const activeKey = navigation.state.routes[navigation.state.index].key;
  const descriptor = descriptors[activeKey];

  const [activeShips, setActiveShips] = useState([]);
  const [launchDate, setLaunchDate] = useState(null);
  const [shipLimits, setShipLimits] = useState([]);

  useEffect(() => {
    const listShipsQuery = async () => {
      const { data } = await API.graphql(graphqlOperation(queries.listShips));
      if (data) {
        const {
          launchDate,
          listShips: { items: shipList },
          getShipLimits = []
        } = data;
        setLaunchDate(launchDate);
        setActiveShips(shipList);
        setShipLimits(getShipLimits);
      }
    };
    listShipsQuery();
  }, []);
  return (
    <View style={styles.container}>
      <View style={styles.navContainer}>
        <Image
          style={{ height: 45, width: 45 }}
          source={require("../images/treasure-chest.svg")}
          resizeMode="contain"
        />
        <Link routeName="Home" navigation={navigation}>
          <View
            style={{
              paddingLeft: 15,
              flexDirection: "row",
              alignItems: "center",
              paddingRight: 17
            }}
          >
            <TreasureHeaderText style={{ paddingRight: 4 }}>
              Treasure
            </TreasureHeaderText>

            <TreasureHeaderText style={{ fontSize: "0.8rem", marginTop: -4 }}>
              of
            </TreasureHeaderText>
            <TreasureHeaderText
              style={{
                fontSize: "0.8rem",
                paddingRight: 2,
                marginTop: 5,
                marginLeft: -4
              }}
            >
              the
            </TreasureHeaderText>
            <TreasureHeaderText>Seas</TreasureHeaderText>
          </View>
        </Link>

        {Object.keys(descriptors)
          .filter((d, i) => i > 0 && d !== "CannonGame")
          .map(d => (
            <View key={d} style={{ padding: 16, justifyContent: "center" }}>
              <Link
                routeName={descriptors[d].navigation.state.routeName}
                navigation={navigation}
              >
                <NavText
                  style={{
                    opacity:
                      activeKey === descriptors[d].navigation.state.routeName
                        ? 1
                        : 0.6
                  }}
                >
                  {descriptors[d].options.linkName ||
                    descriptors[d].navigation.state.routeName}
                </NavText>
              </Link>
            </View>
          ))}

        <View style={{ padding: 16, justifyContent: "center" }}>
          <a
            href="https://discord.gg/Y8SSC65"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: 105 }}
          >
            <NavText>Discord</NavText>
          </a>
        </View>
      </View>
      <Countdown launchDate={launchDate} />
      <View style={{ paddingTop: 10 }}>
        <SceneView
          component={descriptor.getComponent()}
          navigation={descriptor.navigation}
          screenProps={{ activeShips, shipLimits }}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { width: "100%", backgroundColor: "#222", padding: 10 },
  linkText: {
    color: "white",
    fontWeight: "bold",
    fontFamily: "Futura Medium"
  },
  linkNav: { padding: 15 },
  navContainer: {
    height: 75,
    width: "100%",
    padding: 15,
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: Colors.PURPLE
  }
});
