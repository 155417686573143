export function hashJSON() {
  const search = document.location.hash;
  if (search.length === 0) {
    return undefined;
  }
  return JSON.parse(
    '{"' +
      decodeURI(search)
        .replace(/#/, "")
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
}
