import React, { useEffect } from "react";
import { StyleSheet, Text, ScrollView, View } from "react-native";
import { styles } from "./ui";

export default function Install() {
  useEffect(() => {
    location.href = "https://www.twitch.tv/ext/nwbwiwa1w0qcrm1lwa1ydpft0um2sz";
  }, []);

  return (
    <View style={{ flex: 1 }}>
      <Text
        style={[
          styles.rulesText,
          { fontFamily: "Bangers Regular", fontSize: 20 }
        ]}
      >
        Install
      </Text>
    </View>
  );
}

Install.path = "install";
Install.navigationOptions = {
  title: "Treasure of the Seas - Install",
  linkName: "Install"
};
