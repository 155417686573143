module.exports.createShip = `mutation CreateShip($input: CreateShipInput!) {
  createShip(input: $input) {
    streamerId
  }
}
`;

module.exports.createCharacter = `mutation CreateCharacter($input: CreateCharacterInput!) {
  createCharacter(input: $input) {
    streamerId
    id
  }
}
`;

module.exports.createCannonPlayer = `mutation CreateCannonPlayer($input: CreateCannonPlayerInput!) {
  createCannonPlayer(input: $input) {
    displayName
    id
    topScore
    createdAt
  }
}
`;

module.exports.createResource = `mutation CreateResource($input: CreateResourceInput!) {
  createResource(input: $input) {
    streamerId
    position {
      x
      y
    }
    resourceType
  }
}
`;

module.exports.incrementCharacterResource = `mutation IncrementAmounts($input: IncrementCharacterResourceAmount!) {
  incrementCharacterResource(input: $input) {
    streamerId
    woodAmount
		rumAmount
  }
}`;

module.exports.incrementShipResource = `mutation IncrementShipResourceAmounts($input: IncrementShipResourceAmount!) {
  incrementShipResource(input: $input) {
    streamerId
    woodAmount
		rumAmount
    reachDestinationProbability
  }
}`;

module.exports.incrementCannonGameStreakEntry = `mutation IncrementCannonGameStreakEntry($id: Int!, $currentScore: Int!) {
  incrementCannonGameStreakEntry(id: $id, currentScore: $currentScore) {
    id
    displayName
    topScore
  }
}`;
