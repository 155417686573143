module.exports.getCharacter = `query GetCharacter($id: ID!, $streamerId: Int!) {
  getCharacter(id: $id, streamerId: $streamerId) {
    streamerId
    id
    woodAmount
    rumAmount
    rank
  }
}`;

module.exports.listCannonGameStreakEntries = `query ListCannonGameStreakEntries {
  listCannonGameStreakEntries {
    items {
      id
      topScore
      displayName
      updatedAt
    }
  }
  getPlunderWindows {
    waitWindowDuration
    plunderWindowDuration
  }
}`;

module.exports.listShips = `query ListShips(
  $filter: ModelShipFilterInput
  $limit: Int
  $nextToken: String
) {
  launchDate
  getShipLimits {
    phase
    woodNeeded
    pirateCapacity
  }
  listShips(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      streamerId
      createdAt
      name
      isLaunchable
      capacity
      reachDestinationProbability
      woodAmount
      rumAmount
      phase
    }
    nextToken
  }
}
`;

// TODO: define this in the schema w/ an input?
module.exports.initialQuery = streamerId => {
  return `query InitalQuery {
  siteUrl
  launchDate

  getShipLimits {
    phase
    woodNeeded
    pirateCapacity
  }

  getPlunderWindows {
    waitWindowDuration
    plunderWindowDuration
  }
  
  listShips(filter: {
    streamerId: {
      eq: ${streamerId}
    }
  }) {
    items {
      streamerId
      name
      createdAt
      capacity
      woodAmount
      rumAmount
      reachDestinationProbability
      phase
    }
  }

  listCharacters(filter: {
    streamerId: {
      eq: ${streamerId}
    }
    twitchUserName: {
      gt: " " # hack to filter out null
    }
  }, limit: 500) {
    items {
      id
			rank
      streamerId
			woodAmount
	    rumAmount
      twitchUserName
      twitchLogo
    }
  }

  listCannonGameStreakEntries {
    items {
      id
      topScore
      displayName
      streamerId
      updatedAt
    }
  }

}`;
};
